.hero-data-p {
  margin-top: 209px;
  margin-bottom: 15%;
}
.hero-data-p .hero-primary-text {
  color: var(--white, var(--white, #fff));
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
}
.hero-data-p .hero-primary-sub-text {
  color: var(--white, var(--white, #fff));
  font-size: 16px;
  font-weight: 400;
  padding-right: 25px;
  margin-bottom: 48px;
}
.p-hero-img img {
  margin-top: 10%;
  width: 725px;
}
.p-s2-container {
  margin-top: 80px;
}
.products-row {
  padding: 48px 56px;
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
}

.p-head-text {
  font-size: 18px;
  font-weight: 700;
}
.p-sub-heading {
  color: var(--primary-black, #1a1a1a);
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin: 24px 0 0 0;
}
.p-sub-head-bdr {
  margin: 32px 0 32px 0;
  width: 100px;
  height: 5px;
  border-radius: 10px;
  stroke-width: 5px;
}
.p-body-text {
  color: var(--primary-black, #1a1a1a);
  font-size: 16px;
  font-weight: 400;
}
.p-s2-container .btn-with-arrow {
  font-size: 15px;
  padding: 10px 20px;
}
.stroke-blue {
  stroke: #1c64f2 !important;
}
.btn-outline-primary:hover .stroke-blue {
  stroke: #fff !important;
}
.mt-32 {
  margin-top: 32px;
}
.pb-80 {
  padding-bottom: 80px;
}

.product-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* Mobile View */
@media screen and (max-width: 767px) {
  .hero-data-p {
    margin-top: 100px;
  }
  .hero-data-p .hero-primary-text {
    font-size: 32px;
  }
  .p-hero-img img {
    margin-top: 0px;
    width: 98vw;
  }
  .p-s2-container {
    margin-top: 40px;
  }
  .products-row {
    padding: 20px 10px;
    border-radius: 32px;
  }
  .p-sub-heading {
    font-size: 22px;
    margin: 20px 0 0 0;
  }
  .p-sub-head-bdr {
    margin: 12px 0;
  }
  .p-s2-container .btn-with-arrow {
    font-size: 15px;
    padding: 10px 20px;
    margin-bottom: 30px;
  }
  .stroke-blue {
    stroke: #1c64f2 !important;
  }
  .btn-outline-primary:hover .stroke-blue {
    stroke: #fff !important;
  }
  .mt-32 {
    margin-top: 22px;
  }

}
