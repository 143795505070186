.Header {
  padding: 0;
  /* To give smoote change */
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
  max-width: 100vw;
}
.scrolled {
  background-color: #fff !important;
}
.navbar_container {
  max-width: 1250px;
  margin: auto;
  padding: 10px 10px !important;
}
.navbar-nav {
  align-items: center;
}

.navbar_container .navbar-brand .voosh_logo {
  height: 56px;
}
.navbar_container .navbar-nav a {
  font-weight: 500;
  font-size: 16px;
  margin: 0 16px;
  color: #fff;
}
.navbar-link {
  color: #1a1a1a;
  height: min-content;
}
.navbar-link-active {
  color: #1c64f2 !important;
}
.navbar_container .navbar-nav .book_demo_btn {
  padding: 10px 20px;
  border-radius: 8px;
  background: #1c64f2;
  font-size: 14px;
  color: #fff;
}
.scrolled .navbar_container .navbar-nav a {
  color: #1a1a1a;
}
.scrolled .navbar_container .navbar-nav .book_demo_btn {
  color: #fff;
}

.dropdown-menu a{
  color: #000 !important;
}
.dropdown-menu .col{
  border-radius: 8px;
  padding: 5px 0;
}
.dropdown-menu .col:hover{
  background-color: rgb(240, 237, 237);
  transition: all ease-in-out .5s;
}
.dropdown-menu{
  min-width: 320px;
}
.dnav-ico{
  padding: 5px;
  border-radius: 12px;
}
.dnav-text{
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}
.dnav-stext{
  color: grey;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 0px;
}

/* Tab View */

@media screen and (min-width: 767px) and (max-width: 1023px) {
}

/* Mobile View */

@media screen and (max-width: 767px) {
  .navbar_container {
    padding: 8px 20px !important;
  }
  .navbar_container .navbar-brand .voosh_logo {
    height: 3.5rem;
  }
  .navbar_container .navbar-nav a {
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    color: #1a1a1a;
  }
  .navbar_container .navbar-nav .book_demo_btn {
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    font-weight: 600;
    padding: 8px 15px;
    width: fit-content;
  }
  .navbar-nav {
    align-items: unset;
  }
  .navbar-link {
    color: #1a1a1a;
  }
  .navbar-link-active {
    color: #1c64f2 !important;
  }
  .navbar-toggle-icon-white {
    border-color: white;
    filter: brightness(0) invert(1);
  }
  .navbar-toggle-icon-black {
    border-color: black;
    filter: brightness(0) invert(0);
}
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}
