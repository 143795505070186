.as-section2 .row-1{
    margin-top: 52px !important;
    padding: 50px !important;
}
.os-text .t1{
    color: var(--primary-black, #1A1A1A);
    font-size: 32px;
    font-weight: 600;
}
.os-text .t2{
    color: var(--primary-black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
    padding-right: 10px;
    margin-bottom: 32px;
}
.os-small-card1,.os-small-card2,.os-small-card3,.os-small-card4{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 8px 24px 0px rgba(54, 54, 54, 0.05);
    padding: 24px;
    justify-content: center;
}
.os-small-card3{
    padding: 20px !important;
}
.os-small-card-row1{
    margin-right: 10px;
    margin-left: 50px;
}
.os-small-card-row2{
    margin-top: 24px;
}
.os-small-card p{
    color: var(--primary-black, #1A1A1A);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
}
.text-blue{
    color: #3C6BF3;
}
.big-card1, .big-card2, .big-card3{
    border-radius: 24px;
    background: var(--white, #FFF);
    box-shadow: 0px 8px 24px 0px rgba(26, 26, 26, 0.10);
    padding: 32px;
}
.big-card1{
    border-left: 5px solid #FF7043;
}
.big-card2{
    border-left: 5px solid #1A56DB;
    margin-bottom: 32px;
}
.big-card3{
    border-left: 5px solid #6C42C1;
}
.big-card-row .p1{
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
}
.big-card-row .p2{
    color: #1A1A1A;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.blue-card-row{
    border-radius: 24px;
    background: linear-gradient(189deg, #3C6BF3 0%, #021F73 99.72%);
    padding: 64px 80px;
    margin-top: 50px !important;
}
.blue-card-row .p1{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
}
.blue-card-row .p2{
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 48px;
}
.au-dm-img{
    width: 575px;
    border-radius: 20px;
}
.w-v-text{
    color: var(--primary-black, #1A1A1A);
    font-size: 36px;
    font-weight: 700;
    margin-top: 56px;
    margin-bottom: 68px;
}
.au-accordion{
    padding-bottom: 200px;
}
.au-a-h-text{
    color: var(--primary-black, #1A1A1A);
    font-size: 24px;
    font-weight: 700;
}
.acc-h-bdr{
    height: 3px;
    background-color: #3C6BF3;
    stroke-width: 5px;
    border-radius: 10px;
    width: 250px;
    margin-bottom: 24px;
}
.fs-16px{
    font-size: 16px;
}
.accordion{
    border: none !important;
}
.accordion-item{
    border: none !important;
}
.accordion-body{
    padding-top: 0px !important;
}
.accordion-button:not(.collapsed){
    background-color: #FBFCFF;
    box-shadow: none;
    border-color: #BFD0FF !important;
    border-width: 3px;
    border-style: solid solid none solid !important;
}
.accordion-body{
    border-color: #BFD0FF !important;
    border-width: 3px;
    border-style: none solid solid solid !important;
}
.accordion-button:focus {
    border: none;
    box-shadow: none;
    /* z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); */
}
.accordion-item {
    background-color: #FBFCFF !important;
}
.accordion-button::after{
    background-image: url('../images/plus.svg') !important;
}
.accordion-button:not(.collapsed)::after {
    background-image: url('../images/cross.svg') !important;
}
.acc-img1, .acc-img2, .acc-img3, .acc-img4{
    position: absolute;
    left: 105%;
}

.acc-img1{
    margin-top: -230px;
}
.acc-img2{
    margin-top: -260px;
}
.acc-img3{
    margin-top: -320px;
}
.acc-img4{
    margin-top: -385px;
}

/* Mobile view css starts */

@media screen and (max-width: 767px) {
    .as-section2 .row-1{
        margin-top: 20px !important;
        padding: 0px !important;
    }
    .os-small-card1,.os-small-card2,.os-small-card3,.os-small-card4{
        padding: 10px;
    }
    .os-small-card3{
        padding: 10px !important;
    }
    .os-small-card-row1{
        margin-top: 50px;
        margin-right: 0px;
        margin-left: 0px;
    }
    .os-small-card p{
        font-size: 16px;
        font-weight: 600;
    }
    .big-card-row{
        padding-top: 40px;
    }
    .big-card1, .big-card2, .big-card3{
        padding: 16px;
    }
    .big-card2{
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .big-card-row .p2{
        font-size: 20px;
    }
    
    .blue-card-row{
        padding: 20px 10px;
    }
    .blue-card-row .p2{
        font-size: 25px;
        margin-bottom: 20px;
    }
    .au-dm-img{
        width: 100%;
        margin-top: 30px;
    }
    .w-v-text{
        font-size: 30px;
        margin-bottom: 32px;
    }
    .au-accordion{
        padding-bottom: 50px;
    }
    .au-a-h-text{
        font-size: 20px;
    }
    .acc-h-bdr{
        width: 180px;
        margin-bottom: 18px;
    }
    .acc-img1, .acc-img2, .acc-img3, .acc-img4{
        position: relative;
        left: 0%;
        margin-top: 0px;
    }
    .acc-img1 img, .acc-img2 img, .acc-img3 img, .acc-img4 img{
        width: 100%;
    }
}