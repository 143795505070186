@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  position: relative;
  font-family: 'Inter', sans-serif;
}

span {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none !important;
}
