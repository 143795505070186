.ul-outline{
    list-style: none !important;
    margin-top: 20px;
    font-size: 20px;
    transition: 1s;
}
.ul-outline a{
    color: #000;
}
.ul-outline a font{
    font-size: 16px;
    padding-right: 10px;
    color: #F0511F;
    transition: 1s;
}

.ss-bdr-top2{
    height: 1px;
    border-top: 1px solid #7A7A7A;
    margin-top: 30px;
}
.ul-outline li :hover{
    font-weight: 600;
}
.ul-2 li{
    font-size: 18px;
    padding-left: 5px;
}
.rtss2{
    color: var(--primary-black, #1A1A1A);
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
}
.rtss1{
    font-size: 22px;
    font-weight: 600;
    color: var(--primary-black, #1A1A1A);
    margin-top: 30px;
}
.ss-hero-data{
    padding: 179px 110px 116px 110px;
}
.ss-h{
    font-size: 60px;
    padding-bottom: 0px;
}
.ss-sh{
    padding-bottom: 0px;
}
.ss-tabs{
    margin-top: 30px;
    margin-bottom: 32px;
    border: none !important;
    border-bottom: 2px solid #E0E0E0 !important;
}
.nav-tabs .nav-link {
    border: none !important;
    color: #ABACAC;
}
.nav-item .active{
    border-bottom: 2.5px solid #F0511F !important;
}
.blog-card{
 max-width: max-content;
 min-height: max-content;
 margin-bottom: 28px;
}
.blog-card .t2{
    color: var(--secondary-grey, #7A7A7A);
    font-size: 11px;
    font-weight: 400;
}
.blog-card .t3{
    color: var(--secondary-grey, #7A7A7A);
    font-size: 14px;
    font-weight: 400;
}
.ss-card{
    display: flex;
    width: 588px;
    align-items: flex-start;
    margin-bottom: 28px;
}
.ss-card-img{
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px 0px 0px 12px;
}
.farm-burger-card-img{
    background: url('../images/farm-burger-card.png');
    background-repeat: round;
}
.plnt-burger-card-img{
    background: url('../images/plnt-burger-card.png');
    background-repeat: round;
}
.burger-bonaza-card-img{
    background: url('../images/burger-bonaza-card.png');
    background-repeat: round;
}
.kitchen-united-card-img{
    background: url('../images/blogs/sstory-1.webp');
    background-position-x: center;
}
.and-pizza-card-img{
    background: url('../images/blogs/sstory-2.webp');
    background-position-x: center;
}
.blog1-img{
    background: url('../images/blogs/Picture1.webp');
    background-position-x: center;
}
.blog2-img{
    background: url('../images/blogs/Picture2.webp');
    background-position-x: center;
}
.blog3-img{
    background: url('../images/blogs/Picture3.webp');
    background-position-x: center;
}
.blog4-img{
    background: url('../images/blogs/Picture4.webp');
    background-position-x: center;
}
.ss-card-content{
    display: flex;
    width: 378px;
    padding: 19px 19px 25px 19px;
    flex-direction: column;
    align-items: flex-start;
    gap: 19px;
    flex-shrink: 0;
    border-radius: 0px 12px 12px 0px;
    border: 1.3px solid #E0E0E0;
    background: #FFF;
}
.card-head-text{
    color: var(--black, #000);
    font-family: Inter;
    font-size: 15.841px;
    font-style: normal;
    font-weight: 500;
}
.ss-card-b1{
    padding: 9px;
    border-radius: 6px;
    background: var(--background-grey, #F3F3F3);
}
.ss-card-b1 .t1{
    color: var(--secondary-grey, #808080);
    font-size: 19px;
    font-weight: 400;
}
.ss-card-b1 .t2{
    color: var(--secondary-grey, #7A7A7A);
    font-size: 11px;
    font-weight: 500;
    width: max-content;
}
.ss-card .t2{
    color: var(--secondary-grey, #7A7A7A);
    font-size: 11px;
    font-weight: 400;
}
.ss-card .t3{
    color: var(--secondary-grey, #7A7A7A);
    font-size: 14px;
    font-weight: 400;
}
.ss-card .btn-with-arrow{
    margin-top: 20px;
}
.ss-bdr-top{
    height: 1px;
    margin-top: 100px;
    border-top: 1px solid #D1D5DB;
}
.s-nav-flex{
    margin-top: 18px;
    gap: 15px;
}
.home-icon{
    width: 32px;
    background: #F7F7F7;
    padding-left: 7px;
    border-radius: 8px;
}
.nav-ss-t{
    background: #F7F7F7;
    padding: 4px;
    border-radius: 12px;
}
.s-nav-flex font{
    color: var(--black, #000);
    font-size: 16px;
    font-weight: 500;
}
.sstory-head{
    margin-top: 48px;
    color: #000;
    font-size: 40px;
    font-weight: 700;

}
.ss-s1{
    margin-top: 55px;
    margin-bottom: 80px;
}
.ss-s2{
    margin-top: 55px;
}
.avtar-i{
    width: 48px;
    height: 48px;
}
.dot-li{
    width: 6px;
    height: 6px;
    background-color: #CACACA;
    border-radius: 5px;
}
.sub-row-ss p{
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}
.ss-card1{
    border-radius: 16px;
    background: linear-gradient(45deg, #EBF0FF 0%, rgba(248, 249, 255, 0.50) 100%);
    padding: 28px 24px;
    margin-top: 24px;
}
.ss-card1 p{
    color: #808080;
    font-size: 16px;
    font-weight: 500;
}
.ss-card1 li{
    color: var(--black, #000);
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 10px;
}
.text-row-ss{
    margin-top: 52px;
}
.t-h-ss{
    display: flex;
    color: var(--primary-black, #1A1A1A);
    font-size: 36px;
    font-weight: 600;
}
.t-h-ss p{
    margin-bottom: 0px;
}
.t-h-ss .t-s{
    color: var(--voosh-orange, #FF7043);
    font-size: 16px;
    font-weight: 700;
}
.t-h-bdr{
    width: 130px;
    height: 4px;
    border-top: 4px solid #FF7043;
    border-radius: 5px;
}
.raw-text-ss{
    color: var(--primary-black, #1A1A1A);
    font-size: 18px;
    font-weight: 400;
    margin-top: 38px;
}
.text-row-ss li{
    padding-bottom: 10px;
}
.ss-card1 .t-text-ss{
    color: #001F6B;
    font-size: 20px;
    font-weight: 500;
}
.t-text-ss2{
    color: #001F6B;
    font-size: 16px;
    font-weight: 400;
}
.ss-text-content{
    width:880px;
    margin-top:-500px;
}
.ss-text-content2{
    width:880px;
    margin-top:-570px;
}

div.page-nav{
    margin-left: 74%;
    border-radius: 10px;
    position: -webkit-sticky;
    position: sticky ;
    top: 110px; /* required */
    z-index: 1;
    padding: 24px;
    box-shadow: 0px 8px 24px 0px rgba(54, 54, 54, 0.10);
    width: max-content;
}
div.page-nav2{
    box-shadow: none;
    border-radius: 0;
}
.page-nav2 .content{
    border-left: solid rgb(212, 210, 210) 2px;
}
.page-nav2 a div{
    padding-left: 10px;
    margin-left: -2px;
}
.page-nav .content_head{
    color:#808080;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}
.page-nav .content{
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.page-nav .content div{ 
    padding-bottom: 20px;
    color: #5b5b5b;
}
.page-nav .content div:hover{
    color: #000;
}
.page-nav2 a div.active{
    border-left: solid gray 2px;
    color: #000;
}
.page-nav .content div font{ 
    font-size: 12px;
    color: #808080;
    padding-right: 5px;
}
.page-nav .content-btm{
    color: #2D66F5;
    font-size: 16px;
    font-weight: 500;
    margin: 16px 0px;
}
.page-nav .mb8{
    margin-bottom: 8px !important;
}
.blog4 .page-nav .content div{
    padding-bottom: 8px !important;
}
.mb2rem{
    margin-bottom: 1.5rem !important;
}
.v-a-n1, .v-a-n2{
    width: 75% !important;
}
.v-a-n1 img, .v-a-n2 img {
    width: 70%;
}
.rtss-head{
    font-size: 36px;
    font-weight: 600;
}
.wiv-btn{
    text-align: center;
    margin-top: 10px;
}
.wiv-btn .btn-primary{
    border-radius: 8px;
    padding: 14px 24px 14px 24px;
    width: 300px;
}
.wiv-btn .btn-text{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding-right: 8px;
}
.wiv-page ul li{
    font-size: 18px;
    font-weight: 400;
}
.whatisvoosh img{
    width: 130%;
    margin-top: 120px;
}
.s1v1-header-wiv{
    color: #fff;
    font-size: 50px;
    font-weight: 700;
    margin-top: 24px;
    line-height: inherit;
}
.vior-img{
    width: 50%;
    margin-top: 20px;
}
.catch-up-blog{
    margin-top: 30px;
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(270.01deg, rgba(255, 255, 255, 0) -1.83%, rgba(23, 23, 23, 0.75) 48.34%, rgba(255, 255, 255, 0) 103.12%);
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.catch-up-blog .row{
    background-color: #F7F7F7;
    transition: all 0.5s ease-in-out;
}
.catch-up-blog .row:hover{
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
}
.catch-up-blog img{
    height: 90px;
    width: auto;
    border-radius: 10px;
}
.blog-yt-iframe{
    width: 560px;
    height: 320px;
    border-radius: 0.375rem;
}

/* Mobile View */

@media screen and (max-width: 767px){
    .ss-bdr-top{
        margin-top: 80px;
    }
    .catch-up-blog{
        margin: 0px 20px !important;
    }
    .catch-up-blog .row{
        overflow: hidden;
        padding-bottom: 0px !important;
        margin-bottom: 15px !important;
    }
    .catch-up-blog img{
        height: 70px;
        margin-left: -20px ;
    }
    .catch-up-blog .rtss2{
        font-size: 16px;
    }
    .catch-up-blog .s-t2{
        font-size: 14px;
        margin-top: -10px;
    }
    .ug-sub-txt{
        padding-left: 20px;
    }
    .ss-hero-data{
        padding: 100px 20px 30px 10px;
    }
    .ss-h{
        font-size: 40px;
    }
    .rtss1{
        font-size: 20px;
    }
    .ss-card{
        width: 100%;
    }
    .farm-burger-card-img{
        background-repeat:no-repeat;
    }
    .plnt-burger-card-img{
        background-repeat: no-repeat;
    }
    .kitchen-united-card-img{
        background-repeat: no-repeat;
    }
    
    .ss-card-content{
        width: 200px;
        padding: 10px;
        gap: 10px;
    }
    .card-head-text{
        font-size: 12px;
    }
    .ss-card-b1{
        padding: 5px;
        margin-bottom: 8px;
    }
    .ss-card-b1 .t1{
        font-size: 16px;
    }
    .ss-card-b1 .t2{
        font-size: 10px;
        width: max-content;
    }
    .m-btn-ss{
        padding: 5px !important;
        font-size: 12px;
    }
    .m-btn-ss svg{
        width: 20px !important;
        height: 19px !important;
    }
    /* end of success stories home page */
    .mv-overfloX-h{
        overflow-x: hidden;
    }
    .s-nav-flex{
        margin-top: 10px;
        justify-content:left !important;
    }
    .home-icon{
        background: #fff;
    }
    .nav-ss-t{
        background: #fff;
    }
    .s-nav-flex font{
        font-size: 12px;
    }
    .sstory-head{
        margin: 20px;
        font-size: 20px;
    }
    .v-ss-brand-img{
        width: 100%;
        padding: 0 20px 0 10px;
        margin-bottom: 20px;
    }
    .mb2rem{
        margin-bottom: 0 !important;
    }
    .ss-s1{
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .ss-s2{
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .avtar-i{
        width: 30px;
        height: 30px;
    }
    .sub-row-ss{
        margin: 20px 10px;
    }
    .sub-row-ss p{
        font-size: 11px;
    }
    .ss-card1{
        padding: 20px 20px;
        margin-top: 15px;
        height: auto;
    }
    .ss-card1 p{
        font-size: 14px;
    }
    .ss-card1 li{
        font-size: 16px;
    }
    .text-row-ss{
        margin: 20px;
    }
    .t-h-ss{
        font-size: 24px;
    }
    .t-h-ss p{
        margin-bottom: 0px;
    }
    .t-h-ss .t-s{
        font-size: 14px;
    }
    .t-h-bdr{
        width: 80px;
    }
    .raw-text-ss{
        font-size: 16px;
        margin-top: 38px;
        margin-right: 20px;
        width: 94%;
    }
    .text-row-ss li{
        font-size: 14px;
        padding-bottom: 10px;
    }
    .ss-w-75{
        width: 100% !important;
    }
    .ss-card1 p{
        padding-left: 10px;
    }
    .ss-card1 .t-text-ss{
        font-size: 16px;
    }
    .t-text-ss2{
        font-size: 14px;
    }
    div.page-nav{
        display: none;
    }
    .ss-text-content, .ss-text-content2{
        width:auto;
        margin-top:auto !important;
    }
    .outline-tab .t-h-ss{
        padding-left: 15px;
    }
    .outline-tab .t-h-bdr{
        margin-left: 15px;
    }
    .v-a-n1, .v-a-n2{
        width: 100% !important;
    }
    .v-a-n1 img, .v-a-n2 img {
        width: 100%;
    }
    .wiv-btn .btn-primary{
        width: 250px;
    }
    .wiv-btn .btn-text{
        font-size: 16px;
    }
    .whatisvoosh img{
        width: 100%;
        margin-top: 10px;
    }
    .s1v1-header-wiv{
        font-size: 32px;
        padding-top: 22px;
    }
    .vior-img{
        width: 80%;
        margin-top: 20px;
    }
    .blog-yt-iframe{
        margin: 5px;
        width: 98%;
        height: 280px;
    }
    .mt-32-neg{
        margin-top: -32px;
    }

}
